import { debounce } from "lodash";

export default {
    props: {
        label: {
            type: String,
            default: "",
        },
        progress: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        placeholderCapitalized: {
            type: Boolean,
            default: true,
        },
        itemValue: {
            type: String,
            default: "default",
        },
        itemTitle: {
            type: String,
            default: "name",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        list: {
            type: [Array, Object],
            default: null,
        },
        requestInterval: {
            type: Number,
            default: 700,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            openSelect: false,
            selectedItems: [],
        };
    },
    methods: {
        changeSelect(item) {
            this.openSelect = true;
            if (this.checkIsItemsSelected(item)) {
                this.removeItemFromArray(item);
            } else {
                this.addItemToArray(item);
            }
            this.$emit("change-list", this.selectedItems);
            this.openSelect = true;
        },

        addItemToArray(item) {
            this.selectedItems.push(item);
        },

        removeItemFromArray(item) {
            this.selectedItems = this.selectedItems.filter(
                (el) => el[this.itemValue] !== item[this.itemValue]
            );
        },

        checkIsItemsSelected(item) {
            return this.selectedItems.includes(item);
        },

        changeShowTooltipList: debounce(function(status) {
            this.openSelect = status;
        }, 100),
    },
};
